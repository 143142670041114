<template>
  <RevDialog
    :close-button-label="i18n(dsTranslations.close)"
    data-test="ask-bill-modal"
    :name="MODAL_NAME"
    :title="i18n(translations.title)"
  >
    <template #body>
      <div class="body-1 mb-7 mt-5">
        <p>{{ i18n(translations.text1) }}</p>
        <br />
        <p>{{ i18n(translations.text2) }}</p>
        <p>{{ i18n(translations.text3) }}</p>
      </div>
    </template>

    <template #footer="{ close }">
      <RevButton full-width="always" variant="primary" @click="close">
        {{ i18n(translations.button) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { dsTranslations } from '@ds/translations'

import { MODAL_NAME } from './AskBillModal.constants'
import translations from './AskBillModal.translations'

const i18n = useI18n()
</script>
