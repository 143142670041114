<template>
  <RevDialog
    :close-button-label="i18n(dsTranslations.close)"
    :name
    :title="i18n(translations.title)"
  >
    <template #body="{ close }">
      <div class="body-1">
        <p>{{ i18n(translations.first) }}</p>
        <p>{{ i18n(translations.second) }}</p>
        <p>{{ i18n(translations.third) }}</p>
      </div>

      <RevButton
        class="mt-24"
        full-width="always"
        variant="primary"
        @click="close"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { dsTranslations } from '@ds/translations'

import { ORDERLINE_CARD_MODAL } from '../../OrderlineCard.constants'

import translations from './InvoiceRequestedModal.translations'

export interface InvoiceRequestedModalProps {
  orderlineId: number
}

const props = defineProps<InvoiceRequestedModalProps>()

const i18n = useI18n()

const name = computed(
  () => `${ORDERLINE_CARD_MODAL.invoiceRequested}-${props.orderlineId}`,
)
</script>
